
























































import { Component, Vue } from 'vue-property-decorator'
import CudosToken from '@/components/CudosToken.vue'
import type { Validator } from '@/types/validator'
import { Action, Getter } from 'vuex-class'
import { Delegation, PendingReward } from '@/modules/wallet/store'
import { BigNumber, ethers, FixedNumber } from 'ethers'
import TransactionIndicator from '@/components/TransactionIndicator.vue'
import ValidatorSelector from '@/components/ValidatorSelector.vue'

const namespace = 'wallet'

@Component({
  name: 'WalletWithdraw',
  components: {
    CudosToken, TransactionIndicator, ValidatorSelector
  }
})
export default class WalletWithdraw extends Vue {
  @Getter('cudosBalance', { namespace }) cudosBalance!: string
  @Getter('allValidators', { namespace: 'wallet' }) allValidators!: any
  @Getter('allDelegations', { namespace: 'wallet' }) allDelegations!: Promise<Delegation[]>
  @Getter('programmesPerIndex', { namespace: 'wallet' }) programmesPerIndex!: any
  @Action('connectToServiceProvider', { namespace: 'wallet' }) connectToServiceProvider!: any
  @Getter('blockNumber', { namespace: 'wallet' }) blockNumber!: any

  loading = true
  selected: any = null
  gasEstimate: string | undefined = '-'
  gasCost: string | undefined = '-'
  validators = []
  delegations: Delegation[] = []
  programmes: any = {}
  availableWithdraws = BigNumber.from(0)
  currentBlock = Infinity

  async mounted (): Promise<void> {
    await Promise.all([this.allValidators, this.allDelegations, await this.programmesPerIndex, await this.blockNumber])
    this.delegations = await this.allDelegations
    this.programmes = await this.programmesPerIndex
    this.currentBlock = await this.blockNumber
    // @ts-ignore
    this.validators = this.filterValidators(await this.allValidators)
    this.calculateAvailableWithdraws(this.delegations, this.currentBlock)
    this.loading = false
  }

  filterValidators (allValidators: any[]): any[] {
    return allValidators.filter((val: any) => {
      return this.availableWithdraw(val.id).toString() !== '0'
    })
  }

  calculateAvailableWithdraws (delegations: Delegation[], currentBlock: number) {
    delegations.forEach(del => {
      if (del.withdrawalRequested && currentBlock > parseFloat(del.withdrawalPermittedFrom)) {
        this.availableWithdraws = this.availableWithdraws.add(BigNumber.from(del.withdrawalRequestAmount))
      }
    })
  }

  validatorDisabled (validator: any): boolean {
    return !this.hasWithdraws(validator.id)
  }

  validatorExtraRowDataFunction (validator: any): string {
    return `${this.formatEther(this.availableWithdraw(validator.id))}`
  }

  async submit (validator: any) {
    this.selected = validator
    await this.estimatedGas(validator.id, validator.exited)
  }

  hasWithdraws (validatorId: string): boolean {
    return this.delegations
      .filter((del: Delegation) => del.serviceProvider === validatorId && del.withdrawalRequested && this.currentBlock > del.withdrawalPermittedFrom).length > 0
  }

  availableWithdraw (validatorId: string): string {
    const delegation = this.delegations
      .filter((del: Delegation) => del.serviceProvider === validatorId && del.withdrawalRequested && this.currentBlock > del.withdrawalPermittedFrom).pop()
    if (delegation) {
      return delegation.withdrawalRequestAmount
    } else {
      return '0'
    }
  }

  async estimatedGas (validatorId: string, exited: boolean): Promise<void> {
    const serviceProviderContract = await this.connectToServiceProvider(validatorId)
    if (!exited) {
      const fee = await serviceProviderContract.estimateGas.withdrawDelegatedStake()
      this.gasEstimate = ethers.utils.formatEther(fee)
      this.gasCost = (this as any).$utils.formatGasPrice(fee, await (this as any).$services.gasPrices.getAverageGasPrice())
    } else {
      const fee = await serviceProviderContract.estimateGas.exitAsDelegator()
      this.gasEstimate = ethers.utils.formatEther(fee)
      this.gasCost = (this as any).$utils.formatGasPrice(fee, await (this as any).$services.gasPrices.getAverageGasPrice())
    }
  }

  formatEther (gwei: any) {
    return ethers.utils.formatEther(gwei)
  }

  async withdraw () {
    await (this.$refs as any).transactionIndicator.callTransactionFunction()
  }

  currentlyStaked (validatorId: any) {
    const index = this.delegations.findIndex((del: Delegation) => del.serviceProvider === validatorId)
    if (index !== -1) {
      return parseFloat(ethers.utils.formatEther(this.delegations[index].delegatedStake))
    } else {
      return '0'
    }
  }

  async withdrawFunction (): Promise<void> {
    if (this.selected.exited) {
      const hashes = []
      const serviceProviderContract = await this.connectToServiceProvider(this.selected.id)
      const tx = await serviceProviderContract.exitAsDelegator()
      hashes.push(tx.hash)
      await tx.wait()

      await this.$router.push({
        name: 'stakingWithdrawExitValidatorSuccess',
        params: {
          amount: this.currentlyStaked(this.selected.id).toString(),
          validatorId: this.selected.serviceProviderManager as string,
          hashes: hashes.join(',')
        }
      })
    } else {
      const hashes = []
      const serviceProviderContract = await this.connectToServiceProvider(this.selected.id)
      const tx = await serviceProviderContract.withdrawDelegatedStake()
      hashes.push(tx.hash)
      await tx.wait()

      await this.$router.push({ name: 'walletWithdrawSuccess', params: { amount: this.availableWithdraw(this.selected.id), hashes: hashes.join(',') } })
    }
  }
}
