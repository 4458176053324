


























































import { Component, Vue } from 'vue-property-decorator'
import CudosToken from '@/components/CudosToken.vue'
import type { Validator } from '@/types/validator'
import { Action, Getter } from 'vuex-class'
import gql from 'graphql-tag'
import { BigNumber, ethers } from 'ethers'
import { Delegation, PendingReward } from '@/modules/wallet/store'
import TransactionIndicator from '@/components/TransactionIndicator.vue'
import ValidatorSelector from '@/components/ValidatorSelector.vue'

const namespace = 'wallet'

@Component({
  name: 'Rewards',
  components: {
    CudosToken,
    TransactionIndicator,
    ValidatorSelector
  }
})
export default class Rewards extends Vue {
  @Getter('allContracts', { namespace }) allContracts!: any
  @Getter('allPendingRewards', { namespace: 'wallet' }) allPendingRewards!: Promise<PendingReward[]>
  @Getter('allActiveValidators', { namespace: 'wallet' }) allActiveValidators!: any
  @Getter('allDelegations', { namespace: 'wallet' }) allDelegations!: Promise<Delegation[]>
  @Getter('programmesPerIndex', { namespace: 'wallet' }) programmesPerIndex!: any
  @Action('connectToServiceProvider', { namespace: 'wallet' }) connectToServiceProvider!: any
  @Getter('userAccount', { namespace }) userAccount!: string

  loading = true
  selected: any = null
  gasEstimate: string | undefined = '-'
  gasCost: string | undefined = '-'
  validators: any[] = []
  delegations: Delegation[] = []
  pendingRewards: PendingReward[] = []
  availableRewards = BigNumber.from(0)
  programmes: any = {}

  async mounted (): Promise<void> {
    await Promise.all([this.allActiveValidators, this.allDelegations, this.allPendingRewards, await this.programmesPerIndex])
    this.delegations = await this.allDelegations
    this.pendingRewards = await this.allPendingRewards
    this.programmes = await this.programmesPerIndex
    // @ts-ignore
    this.validators = this.filterAvailableValidators(await this.allActiveValidators)
    this.calculateAvailableRewards(this.pendingRewards)
    this.loading = false
  }

  calculateAvailableRewards (pendingRewards: PendingReward[]) {
    pendingRewards.forEach(reward => {
      this.availableRewards = this.availableRewards.add(reward.reward)
    })
  }

  filterAvailableValidators (allValidators: any[]): any[] {
    return allValidators.filter((val: any) => {
      return this.hasPendingRewards(val.id) && !val.exited
    })
  }

  validatorExtraRowDataFunction (validator: any): string {
    return `${this.formatEther(this.pendingReward(validator.id))}`
  }

  validatorDisabled (validator: any): boolean {
    return !this.hasPendingRewards(validator.id)
  }

  pendingReward (validatorId: string): string {
    const pendingReward = this.pendingRewards.filter((pr: PendingReward) => pr.serviceProvider === validatorId).pop()
    if (pendingReward) {
      return pendingReward.reward
    } else {
      return '0'
    }
  }

  hasPendingRewards (validatorId: string): boolean {
    return this.pendingRewards.filter((pr: PendingReward) => pr.serviceProvider === validatorId).length > 0
  }

  async submit (validator: any) {
    this.selected = validator
    await this.estimatedGas(validator.id)
  }

  async estimatedGas (validatorId: string): Promise<void> {
    const serviceProviderContract = await this.connectToServiceProvider(validatorId)
    const fee = await serviceProviderContract.estimateGas.getReward()
    this.gasEstimate = ethers.utils.formatEther(fee)
    this.gasCost = (this as any).$utils.formatGasPrice(fee, await (this as any).$services.gasPrices.getAverageGasPrice())
  }

  currentlyStaked (validatorId: any) {
    const index = this.delegations.findIndex((del: Delegation) => del.serviceProvider === validatorId)
    if (index !== -1) {
      return parseFloat(ethers.utils.formatEther(this.delegations[index].delegatedStake))
    } else {
      return '0'
    }
  }

  async claimRewards (): Promise<void> {
    await (this.$refs as any).transactionIndicator.callTransactionFunction()
  }

  async claimRewardsFunction (): Promise<void> {
    const hashes = []
    await this.estimatedGas(this.selected.id)
    const gasLimit = ethers.utils.parseEther(this.gasEstimate as string).mul(3)
    const serviceProviderContract = await this.connectToServiceProvider(this.selected.id)
    const tx = await serviceProviderContract.getReward({ gasLimit: gasLimit })
    hashes.push(tx.hash)
    await tx.wait()

    await this.$router.push({
      name: 'walletRewardsSuccess',
      params: {
        amount: this.pendingReward(this.selected.id),
        validatorId: this.selected.serviceProviderManager,
        hashes: hashes.join(',')
      }
    })
  }

  formatEther (gwei: any) {
    return ethers.utils.formatEther(gwei)
  }
}
