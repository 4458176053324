


























































































































import CudosToken from '@/components/CudosToken.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import { ethers } from 'ethers'

@Component({
  name: 'ValidatorSelector',
  components: {
    CudosToken
  }
})
export default class ValidatorSelector extends Vue {
  @Getter('delegationsPerValidator', { namespace: 'wallet' }) delegationsPerValidator!: any
  @Getter('programmesPerIndex', { namespace: 'wallet' }) programmesPerIndex!: any

  loaded = false
  delegations: any = {}
  programmes: any = {}
  showIndicator = false
  selectedId = null

  errorMessage: string | null = null

  async mounted (): Promise<void> {
    await Promise.all([await this.delegationsPerValidator, await this.programmesPerIndex])
    this.programmes = await this.programmesPerIndex
    this.delegations = await this.delegationsPerValidator
    this.loaded = true
  }

  currentlyStaked (validatorId: any) {
    if (this.delegations[validatorId]) {
      return parseFloat(ethers.utils.formatEther(this.delegations[validatorId].delegatedStake))
    } else {
      return '0'
    }
  }

  onClick (validator: any): void {
    this.selectedId = validator.id
    this.$emit('validator-selected', validator)
  }

  @Prop({ required: true })
  validators!: any[]

  @Prop({
    required: false,
    default: true
  })
  showTitle!: boolean

  @Prop({
    default: '1. Select a validator'
  })
  title!: string

  @Prop({
    required: false,
    default: 'No available validators'
  })
  noValidatorsText!: string

  @Prop({ required: true })
  btnDisabledFunction!: (validator: any) => boolean

  @Prop({ required: false })
  validatorExtraColumnDataFunction!: (validator: any) => string

  @Prop({ required: false })
  validatorExtraRowDataFunction!: (validator: any) => any

  @Prop()
  subtitle!: string

  @Prop({ default: true })
  showDefaultRow!: boolean

  @Prop()
  extraRowClass!: string

  @Prop()
  extraRowUnit!: string
}
