
























import { Component, Vue } from 'vue-property-decorator'
import CudosToken from '@/components/CudosToken.vue'
import { ethers } from 'ethers'
import { VARIABLES } from '@/variables'
import SuccessTemplate from '@/components/SuccessTemplate.vue'
@Component({
  name: 'WithdrawSuccess',
  components: {
    CudosToken,
    SuccessTemplate
  }
})
export default class SuccessTemplateWithdrawSuccess extends Vue {
  amount = '-'
  url = VARIABLES.ETHERSCAN_URL
  loaded = false
  hashes: any[] = []
  valContext = false
  backToLabel = ''
  backToPath = ''

  mounted (): void {
    this.amount = ethers.utils.formatEther(this.$route.params.amount)
    this.setContext()
    if (this.$route.params && this.$route.params.hashes) {
      this.hashes = this.$route.params.hashes.split(',')
    }
    this.loaded = true
  }

  setContext (): void {
    this.valContext = (this.$route.params.valView === 'true')
    if (this.valContext) {
      this.backToPath = '/validators'
      this.backToLabel = 'Validator'
    } else {
      this.backToPath = '/wallet'
      this.backToLabel = 'Wallet'
    }
  }
}
