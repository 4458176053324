

































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import CudosToken from '@/components/CudosToken.vue'
import { Action, State, Getter } from 'vuex-class'

import { BigNumber, ethers } from 'ethers'
import { PendingReward, Programme } from '@/modules/wallet/store'
import { VARIABLES } from '@/variables'
import TransactionIndicator from '@/components/TransactionIndicator.vue'

const namespace = 'wallet'
@Component({
  name: 'Wallet',
  computed: {
    count () {
      return this.$store.state.wallet.account
    }
  },
  watch: {
    count (newAccount, oldAccount) {
      location.reload()
    }
  },
  components: {
    CudosToken, TransactionIndicator
  }
})
export default class Wallet extends Vue {
  @Action('fetchRecentTransactions', { namespace }) fetchRecentTransactions!: any
  @Action('populateValidators', { namespace }) populateValidators!: any
  @Action('getRecoverableTokens', { namespace }) getRecoverableTokens!: any
  @Action('withdrawRecoverableTokens', { namespace }) withdrawRecoverableTokens!: any
  @Getter('cudosBalance', { namespace }) cudosBalance!: number
  @Getter('deprecatedPendingRewards', { namespace }) deprecatedPendingRewards!: string
  @Getter('getUserTokenRecover', { namespace }) getUserTokenRecover!: BigNumber
  @State('account', { namespace }) account!: string
  @State('recentTransactions', { namespace }) recentTransactions!: any
  @State('userTokenRecover', { namespace: 'wallet' }) userTokenRecover!: any
  @Getter('allActiveValidators', { namespace }) allActiveValidators!: any
  @Getter('allProgrammes', { namespace }) allProgrammes!: any
  @Getter('allPendingRewards', { namespace: 'wallet' }) allPendingRewards!: Promise<PendingReward[]>
  @Getter('allDelegations', { namespace }) allDelegations!: any
  @Getter('allContracts', { namespace: 'wallet' }) allContracts!: any
  @Getter('blockNumber', { namespace: 'wallet' }) blockNumber!: any

  loaded = false
  validators = []
  programmes = []
  availableRewards = BigNumber.from(0)
  delegations = []
  rewardsPerProgramme: any = {}
  url = VARIABLES.ETHERSCAN_URL
  tokenAddress = ''
  tokensToRecover = BigNumber.from(0)
  ethAccount = null

  async mounted (): Promise<void> {
    await Promise.all([this.allActiveValidators, this.allDelegations])
    await this.getRecoverableTokens()
    this.fetchRecentTransactions()
    this.validators = await this.allActiveValidators
    this.programmes = await this.allProgrammes
    this.delegations = await this.allDelegations
    this.calculateAvailableRewards(await this.allPendingRewards)
    this.buildRewardsPerProgramme()
    this.tokenAddress = this.allContracts.cudosToken.address
    this.tokensToRecover = this.userTokenRecover
    this.loaded = true
  }

  buildRewardsPerProgramme () {
    const validatorsToProgramme: any = {}
    this.validators.forEach((validator: any) => {
      validatorsToProgramme[validator.id] = validator.rewardsProgrammeId
    })

    this.programmes.forEach((programme: Programme) => {
      this.rewardsPerProgramme[programme.index] = programme.totalStaked.mul(programme.accTokensPerShare).div(BigNumber.from(10).pow(18))
    })
  }

  calculateAvailableRewards (pendingRewards: PendingReward[]) {
    pendingRewards.forEach(reward => {
      this.availableRewards = this.availableRewards.add(reward.reward)
    })
  }

  getValue (value: string): string {
    const result = ethers.utils.formatEther(value)
    if (!this.$vuetify.breakpoint.mobile && result.length > 18) {
      return result.slice(0, 18) + '...'
    } else if (this.$vuetify.breakpoint.mobile && result.length > 7) {
      return result.slice(0, 7) + '...'
    }
    return result
  }

  getDate (value: number): string {
    const date = new Date(value * 1000)

    if (!this.$vuetify.breakpoint.mobile) {
      return date.toISOString().split('.')[0] + 'Z'
    } else {
      const dateAndTime = date.toISOString().split('T')
      const time = dateAndTime[1].split(':')
      return dateAndTime[0] + 'T' + time[0] + ':' + time[1] + 'Z'
    }
  }

  async withdrawTokens (): Promise<void> {
    await (this.$refs as any).transactionIndicator.callTransactionFunction()
    location.reload()
  }

  formatEther (gwei: any, mobile = false) {
    const formated = ethers.utils.formatEther(gwei)
    if (mobile && formated.length > 11) {
      return formated.slice(0, 11) + '...'
    } else {
      return formated
    }
  }
}
